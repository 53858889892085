<template>
    <el-row>
        <el-col :xl="12" :lg="16">
            <h2>{{form.OrgName || "新增组织"}}</h2>
            <el-form :model="form" :rules="rules" ref="dialogForm" label-width="130px" label-position="left">
                <el-form-item label="组织名称" prop="OrgName" :rules="[{required: true, message: '请输入组织名称', trigger: 'blur'},{max:35,min:2,message: '长度在 2 到 35 个字符', trigger: 'blur'}]">
                    <el-input v-model="form.OrgName" clearable placeholder="请输入组织名称"></el-input>
                </el-form-item>
                <el-form-item label="组织图片" prop="OrgAvatar">
                    <el-upload class="avatar-uploader" :headers="headers" :action="`${this.$CONFIG.API_URL}/File/UploadFileAvatar`" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl.Url" :src="imageUrl.Url" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>

                <el-form-item label="上级组织" prop="OrgPID" :rules="[]">
                    <el-cascader v-model="form.OrgPID" :options="org" :props="orgProps" :show-all-levels="false" clearable></el-cascader>
                    <!-- <div class="el-form-item-msg">这里还有点问题！el-cascader的props的label 现暂只支持字符串还未支持自定义函数 </div> -->
                </el-form-item>
                <el-form-item label="组织信用代码" prop="OrgCode" :rules="[{required: true, message: '请输入组织信用代码', trigger: 'blur'},{max:35,min:10,message: '长度在 10 到 35 个字符', trigger: 'blur'}]">
                    <el-input v-model="form.OrgCode" clearable placeholder="请输入组织信用代码"></el-input>
                </el-form-item>
                <el-form-item label="组织类型" prop="OrgType">
                    <el-radio-group v-model="form.OrgType">
                        <el-radio-button label="0">公司</el-radio-button>
                        <el-radio-button label="1">政府教育局</el-radio-button>
                        <el-radio-button label="2">学校</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="组织地址" prop="OrgAddress">
                    <el-input v-model="form.OrgAddress" clearable placeholder="请输入组织地址" :rules="[{required: true, message: '请输入组织地址', trigger: 'blur'}]"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="RemarkInfo">
                    <el-input type="textarea" maxlength="200" v-model="form.RemarkInfo" label="备注"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submit" :loading="isSubmit">保 存</el-button>
                </el-form-item>
            </el-form>
        </el-col>
    </el-row>
</template>
<style>
</style>
<script>
export default {
    props: {
        org: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            form: {
                OrgPID: -1,
                OrgName: "",
                OrgAvatar: "",
                OrgAvatarUrl: "",
                OrgCode: "",
                OrgAddress: "",
                OrgLongitude: 0,
                OrgLatitude: 0,
                OrgType: 1,
                Id: 0,
                RemarkInfo: "",
            },
            orgProps: {
                value: "Id",
                label: "OrgName",
                children: "Children",
                checkStrictly: true,
            },
            rules: {},
            views: [],
            isSubmit: false,
            imageUrl: {},
            headers: {},
        };
    },
    mounted() {
        this.$nextTick(function () {
            // 仅在整个视图都被渲染之后才会运行的代码
            let adminInfo = this.$TOOL.data.get("adminInfo");
            this.headers = {
                Authorization: "Bearer " + adminInfo.JwtToken,
            };
        });
    },
    methods: {
        handleAvatarSuccess(res) {
            this.imageUrl = res.Data;
            console.log(res.Data);
        },
        beforeAvatarUpload(file) {
            var fileType = file.type;
            const isJPG = !(
                fileType != ".jpg" &&
                fileType != ".JPG" &&
                fileType != "image/jpeg" &&
                fileType != ".png" &&
                fileType != ".PNG" &&
                fileType != "image/png" &&
                fileType != ".gif" &&
                fileType != ".GIF" &&
                fileType != "image/gif"
            );
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error("上传头像图片只能是 图片 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        },
        //表单注入数据
        setData(data, pid) {
            this.form = data;
            this.form.OrgPID = pid;
            console.log(data);
            this.imageUrl = {
                Id: data.OrgAvatar,
                Url: data.OrgAvatarUrl,
            };
            //可以和上面一样单个注入，也可以像下面一样直接合并进去
            //Object.assign(this.form, data)
        },
        submit() {
            this.isSubmit = true;
            this.$refs["dialogForm"].validate((valid) => {
                if (valid) {
                    if (this.form.OrgPID == undefined) this.form.OrgPID = -1;
                    else if (this.form.OrgPID.length > 0)
                        this.form.OrgPID =
                            this.form.OrgPID[this.form.OrgPID.length - 1];
                    else if (this.form.OrgPID == 0) this.form.OrgPID = -1;
                    //判断图片是否上传
                    if (!this.imageUrl) {
                        this.$notify({
                            title: "失败",
                            message: "请上传图片！",
                            type: "error",
                        });
                        return;
                    } else {
                        this.form.OrgAvatar = this.imageUrl.Id;
                    }
                    this.$API.Org.AddOrUpdate.post(this.form).then((res) => {
                        if (res.Code == 204) {
                            this.$notify({
                                title: "成功",
                                message: "操作成功！",
                                type: "success",
                            });
                        } else {
                            this.$notify({
                                title: "失败",
                                message: res.Message,
                                type: "error",
                            });
                        }
                        this.isSubmit = false;
                    });
                } else {
                    this.isSubmit = false;
                    console.log("验证失败");
                }
            });
        },
    },
};
</script>
<style scoped>
h2 {
    font-size: 17px;
    color: #3c4a54;
    padding: 0 0 30px 0;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>